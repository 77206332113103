import { ImageEditorComponent } from './modals/image-editor/image-editor.component';
import { ImageEditor2Component } from './modals/image-editor2/image-editor2.component';
import { MediaDragDropComponent } from './modals/media-drag-drop/media-drag-drop.component';
import { TextToSpeechService } from './services/tts.service';
import { GlobalService } from './services/global.service';
import { MediaService } from './services/media.service';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage';
import { FormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TextToSpeech } from '@ionic-native/text-to-speech/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { MediaCapture, MediaFile, CaptureError, CaptureImageOptions } from '@ionic-native/media-capture/ngx';
import { File } from '@ionic-native/file/ngx';
import { Platform } from '@ionic/angular';
import { AndroidFullScreen } from '@ionic-native/android-full-screen/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { AppLauncher } from '@ionic-native/app-launcher/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { TutorComponent } from './tutor/tutor.component';

import { FilechooserComponent } from './modals/filechooser/filechooser.component';
import { MediaComponent } from './modals/media/media.component';
import { LocationsComponent } from './modals/locations/locations.component';
import { UsersComponent } from './modals/users/users.component';
import { SequencesComponent } from './modals/sequences/sequences.component';

import { MediaoptionsComponent } from './popovers/mediaoptions/mediaoptions.component';
import { OwneroptionsComponent } from './popovers/owneroptions/owneroptions.component';
import { RecognationComponent } from './popovers/recognation/recognation.component';
import { RecognationCallbackWaitComponent } from './popovers/recognation-callback-wait/recognation-callback-wait.component';
import { ResponseBoxComponent } from './popovers/response-box/response-box.component';

import { ColorPickerModule } from 'ngx-color-picker';

import { AngularEditorModule } from '@kolkov/angular-editor';

import { WebView } from '@ionic-native/ionic-webview/ngx';

import { Media } from '@ionic-native/media/ngx';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ColorPickerPageModule } from './popovers/color-picker/color-picker.module';
import { ColorPickerPage } from './popovers/color-picker/color-picker.page';

import { IonicSelectableModule } from 'ionic-selectable';

import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { SelectPage } from './select/select.page';
import { SelectComponent } from './select/select.component';

import { DragulaModule } from 'ng2-dragula';

import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';

// angular-cropperjs
// https://github.com/matheusdavidson/angular-cropperjs
// https://github.com/fengyuanchen/cropperjs
// https://ionicacademy.com/ionic-image-crop-cropperjs/
import { AngularCropperjsModule } from 'angular-cropperjs';

// ngx-image-cropper
// https://www.npmjs.com/package/ngx-image-cropper
// https://github.com/Mawi137/ngx-image-cropper
// https://www.youtube.com/watch?v=UnqVkzWg2W0
import { ImageCropperModule } from 'ngx-image-cropper';

// Custom Hammer Configuration
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';

export class CustomHammerConfig extends HammerGestureConfig {
  overrides = {
    'pan': {
      direction: Hammer.DIRECTION_ALL
    }
  }
}

/*
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
*/
// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    FilechooserComponent,
    MediaComponent,
    LocationsComponent,
    UsersComponent,
    SequencesComponent,
    TutorComponent,
    MediaoptionsComponent,
    OwneroptionsComponent,
    RecognationComponent,
    RecognationCallbackWaitComponent,
    ResponseBoxComponent,
    SelectComponent,
    SelectPage,
    ImageEditorComponent,
    ImageEditor2Component,
    MediaDragDropComponent
  ],
  entryComponents: [
    FilechooserComponent,
    MediaComponent,
    LocationsComponent,
    UsersComponent,
    SequencesComponent,
    TutorComponent,
    MediaoptionsComponent,
    OwneroptionsComponent,
    RecognationComponent,
    RecognationCallbackWaitComponent,
    ResponseBoxComponent,
    ColorPickerPage,
    SelectPage,
    ImageEditorComponent,
    ImageEditor2Component,
    MediaDragDropComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ColorPickerModule,
    IonicStorageModule.forRoot(),
    AngularEditorModule,
    ColorPickerPageModule,
    IonicSelectableModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    ImageCropperModule,
    AngularCropperjsModule,
    DragulaModule.forRoot()
  ],
  providers: [
    StatusBar,
    SplashScreen,
    TextToSpeech,
    Camera,
    MediaCapture,
    File,
    Platform,
    AndroidFullScreen,
    AndroidPermissions,
    CallNumber,
    ScreenOrientation,
    WebView,
    MediaService,
    Media,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    GlobalService,
    TextToSpeechService,
    ColorPickerPage,
    InAppBrowser,
    AppLauncher,
    BarcodeScanner,
    { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
